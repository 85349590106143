export default function ThankYou() {
  return (
    <div>
      <div
        className="m-auto mt-48 max-w-[500px] min-w-[350px] w-full h-64 bg-slate-300 rounded-md
        py-5 px-14 flex justify-center items-center"
      >
        <h1 className="text-lg text-slate-700">
          Thank you! You may exit this page now.
        </h1>
      </div>
    </div>
  );
}
