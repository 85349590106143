import { useEffect, useState } from "react";
import axios from "axios";
import "./App.css";
import useLocalStorage from "./hooks/useLocalStorage";
import Canceled from "./views/Canceled";
import Consent from "./views/Consent";
import DebriefForm from "./views/DebriefForm";
import GambleResults from "./views/GambleResults";
import Game from "./views/Game";
import Instructions from "./views/Instructions";
import PersonalInfo from "./views/PersonalInfo";
import Questionnaire from "./views/Questionnaire";
import Questionnaire2 from "./views/Questionnaire2";
import Questionnaire3 from "./views/Questionnaire3";
import SurveyCode from "./views/SurveyCode";
import ThankYou from "./views/ThankYou";

function App() {
  const [hasCanceled, setHasCanceled] = useState(false);
  const [mturkWorkerId, setMturkWorkerId] = useState("");
  const [token, setToken] = useLocalStorage("token", "");
  const [step, setStep] = useLocalStorage("step", 0);
  const [lSWorkerId, setLSWorkerId] = useLocalStorage("workerId", "");

  const [lossOnLeftInitially, setLossOnLeftInitially] = useLocalStorage(
    "lossOnLeftInitially",
    null
  );
  const [canChooseGamble, setCanChooseGamble] = useLocalStorage(
    "canChooseGamble",
    null
  );

  const onSubmitSurveyData = (responseData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const body = {
      surveyData: responseData,
    };

    axios
      .post(`/api/users/${lSWorkerId}/answers`, body, config)
      .then(() => {
        setStep(step + 1);
      })
      .catch((err) => {
        console.log(err);
        alert(
          "An error occured trying to save this information. Please try again later."
        );
      });
  };

  // On page load
  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);
    if (searchParams.has("workerId")) {
      setMturkWorkerId(searchParams.get("workerId"));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickCancel = () => {
    setHasCanceled(true);
    window.localStorage.removeItem("step");
    window.localStorage.removeItem("userData");
  };

  let Page;

  if (hasCanceled) {
    Page = <Canceled />;
  } else if (step === 0) {
    Page = (
      <PersonalInfo
        workerId={mturkWorkerId}
        onCancel={onClickCancel}
        onSubmit={({ workerId, age, gender }) => {
          const config = {
            headers: {
              "Content-Type": "application/json",
            },
          };

          const body = {
            workerId,
            age,
            gender,
          };

          axios
            .post("/api/users", body, config)
            .then((res) => {
              setToken(res.data.token);
              setCanChooseGamble(res.data.canChooseGamble);
              setLossOnLeftInitially(res.data.lossOnLeftInitially);
              setLSWorkerId(workerId);
              setStep(1);
            })
            .catch((err) => {
              console.log(err);
              alert(
                "An error occured trying to save your information. Please try again later."
              );
            });
        }}
      />
    );
  } else if (step === 1) {
    Page = <Consent onCancel={onClickCancel} onAccept={() => setStep(2)} />;
  } else if (step === 2) {
    Page = <Instructions onAccept={() => setStep(3)} />;
  } else if (step === 3) {
    Page = (
      <Game
        canChooseGamble={canChooseGamble}
        lossOnLeftInitially={lossOnLeftInitially}
        onGameFinish={() => setStep(4)}
      />
    );
  } else if (step === 4) {
    Page = <Questionnaire onSubmit={onSubmitSurveyData} />;
  } else if (step === 5) {
    Page = <Questionnaire2 onSubmit={onSubmitSurveyData} />;
  } else if (step === 6) {
    Page = <Questionnaire3 onSubmit={onSubmitSurveyData} />;
  } else if (step === 7) {
    Page = <GambleResults onContinue={() => setStep(8)} />;
  } else if (step === 8) {
    Page = <SurveyCode onContinue={() => setStep(9)} />;
  } else if (step === 9) {
    Page = <DebriefForm onContinue={() => setStep(10)} />;
  } else if (step === 10) {
    Page = <ThankYou />;
  }

  return <div className="App p-10 text-gray-800">{Page}</div>;
}

export default App;
