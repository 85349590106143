import { useEffect, useRef, useState } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import BoardHeading from "./BoardHeading";
import Button, { ButtonState } from "./Button";
import Card from "./Card";
import DynamicCard from "./DynamicCard";
import Modal from "./Modal";

const ALLOCATED_POINTS = 300;
const TIMEOUT_MS = 3000;

export default function Board({
  lossOnLeft,
  randomSelection,
  winProb,
  onRoundFinish,
  hideInstructions,
}) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [canChooseGamble] = useLocalStorage("canChooseGamble", null);

  const lossProb = 100 - winProb;
  const lossProbText = "Loss Probability";
  const lossAmtText = "Loss Amount";
  const winProbText = "Win Probability";
  const winAmtText = "Win Amount";

  const [confirmedAllocation, setConfirmedAllocation] = useState(false);

  const [clicked1, setClicked1] = useState(false);
  const [clicked2, setClicked2] = useState(false);
  const [clicked3, setClicked3] = useState(false);
  const [clicked4, setClicked4] = useState(false);

  const [points1, setPoints1] = useState(0);
  const [points2, setPoints2] = useState(0);
  const [points3, setPoints3] = useState(0);
  const [points4, setPoints4] = useState(0);

  const amount1Info = useRef(null);
  const amount2Info = useRef(null);
  const amount3Info = useRef(null);
  const amount4Info = useRef(null);

  const [chooseButtonState, setChooseButtonState] = useState(
    ButtonState.DEFAULT
  );
  const [chooseButtonAState, setChooseButtonAState] = useState(
    ButtonState.DEFAULT
  );
  const [chooseButtonBState, setChooseButtonBState] = useState(
    ButtonState.DEFAULT
  );
  const [gambleAVisibility, setGambleAVisibility] = useState("invisible");
  const [gambleBVisibility, setGambleBVisibility] = useState("invisible");

  const timer1Start = useRef(null);
  const timer1End = useRef(null);
  const timer2Start = useRef(null);
  const timer2End = useRef(null);

  const pointsLeft = ALLOCATED_POINTS - (points1 + points2 + points3 + points4);

  // On page load, record the time
  useEffect(() => {
    timer1Start.current = new Date();
  }, []);

  const getGambleInfo = () => {
    let gambleALossPoints;
    let gambleAWinPoints;
    let gambleBLossPoints;
    let gambleBWinPoints;

    let gambleALossAmount;
    let gambleAWinAmount;
    let gambleBLossAmount;
    let gambleBWinAmount;

    let gambleALossAmountShowing;
    let gambleAWinAmountShowing;
    let gambleBLossAmountShowing;
    let gambleBWinAmountShowing;

    if (lossOnLeft) {
      gambleALossPoints = points1;
      gambleBLossPoints = points2;
      gambleAWinPoints = points3;
      gambleBWinPoints = points4;
      gambleALossAmount = amount1Info.current.amount;
      gambleBLossAmount = amount2Info.current.amount;
      gambleAWinAmount = amount3Info.current.amount;
      gambleBWinAmount = amount4Info.current.amount;
      gambleALossAmountShowing = amount1Info.current.amountShowing;
      gambleBLossAmountShowing = amount2Info.current.amountShowing;
      gambleAWinAmountShowing = amount3Info.current.amountShowing;
      gambleBWinAmountShowing = amount4Info.current.amountShowing;
    } else {
      gambleALossPoints = points3;
      gambleBLossPoints = points4;
      gambleAWinPoints = points1;
      gambleBWinPoints = points2;
      gambleALossAmount = amount3Info.current.amount;
      gambleBLossAmount = amount4Info.current.amount;
      gambleAWinAmount = amount1Info.current.amount;
      gambleBWinAmount = amount2Info.current.amount;
      gambleALossAmountShowing = amount3Info.current.amountShowing;
      gambleBLossAmountShowing = amount4Info.current.amountShowing;
      gambleAWinAmountShowing = amount1Info.current.amountShowing;
      gambleBWinAmountShowing = amount2Info.current.amountShowing;
    }

    const timeDiff1 = (timer1End.current - timer1Start.current) / 1000;
    const timeDiff2 = (timer2End.current - timer2Start.current) / 1000;
    const confirmationResponseTime = Math.round(timeDiff1 * 10) / 10;
    const gambleChoiceResponseTime = Math.round(timeDiff2 * 10) / 10;

    return {
      gambleALossPoints,
      gambleAWinPoints,
      gambleBLossPoints,
      gambleBWinPoints,
      gambleALossAmount,
      gambleAWinAmount,
      gambleBLossAmount,
      gambleBWinAmount,
      gambleALossAmountShowing,
      gambleAWinAmountShowing,
      gambleBLossAmountShowing,
      gambleBWinAmountShowing,
      gambleALossPercentage: lossProb,
      gambleAWinPercentage: winProb,
      gambleBLossPercentage: lossProb,
      gambleBWinPercentage: winProb,
      confirmationResponseTime,
      gambleChoiceResponseTime,
    };
  };

  const chooseRandomGamble = () => {
    const runGambleA = Math.random() < 0.5;
    const gambleWon = Math.random() < winProb / 100;
    let rewardAmount = 0;

    const gambleInfo = getGambleInfo();

    if (runGambleA) {
      setGambleAVisibility("visible");
      setTimeout(() => setGambleAVisibility("invisible"), TIMEOUT_MS);
      if (gambleWon) {
        rewardAmount = gambleInfo.gambleAWinAmount;
      } else {
        rewardAmount = gambleInfo.gambleALossAmount;
      }
    } else {
      setGambleBVisibility("visible");
      setTimeout(() => setGambleBVisibility("invisible"), TIMEOUT_MS);
      if (gambleWon) {
        rewardAmount = gambleInfo.gambleBWinAmount;
      } else {
        rewardAmount = gambleInfo.gambleBLossAmount;
      }
    }

    setChooseButtonState(ButtonState.PASS);
    setTimeout(() => {
      setChooseButtonState(ButtonState.DEFAULT);
      onRoundFinish({
        ...gambleInfo,
        gambleChosen: runGambleA ? "A" : "B",
        gambleWon,
        rewardAmount,
      });
    }, TIMEOUT_MS);
  };

  const chooseGambleA = () => {
    const gambleWon = Math.random() < winProb / 100;
    let rewardAmount = 0;

    const gambleInfo = getGambleInfo();

    setGambleAVisibility("visible");
    setTimeout(() => setGambleAVisibility("invisible"), TIMEOUT_MS);
    if (gambleWon) {
      rewardAmount = gambleInfo.gambleAWinAmount;
    } else {
      rewardAmount = gambleInfo.gambleALossAmount;
    }

    setChooseButtonAState(ButtonState.PASS);
    setTimeout(() => {
      setChooseButtonAState(ButtonState.DEFAULT);
      onRoundFinish({
        ...gambleInfo,
        gambleChosen: "A",
        gambleWon,
        rewardAmount,
      });
    }, TIMEOUT_MS);
  };

  const chooseGambleB = () => {
    const gambleWon = Math.random() < winProb / 100;
    let rewardAmount = 0;

    const gambleInfo = getGambleInfo();

    setGambleBVisibility("visible");
    setTimeout(() => setGambleBVisibility("invisible"), TIMEOUT_MS);
    if (gambleWon) {
      rewardAmount = gambleInfo.gambleBWinAmount;
    } else {
      rewardAmount = gambleInfo.gambleBLossAmount;
    }

    setChooseButtonBState(ButtonState.PASS);
    setTimeout(() => {
      setChooseButtonBState(ButtonState.DEFAULT);
      onRoundFinish({
        ...gambleInfo,
        gambleChosen: "B",
        gambleWon,
        rewardAmount,
      });
    }, TIMEOUT_MS);
  };

  const isValidPointsAssignment =
    pointsLeft >= 0 && clicked1 && clicked2 && clicked3 && clicked4;

  const PreSelectionInstructionText = () => (
    <div>
      <p>
        Decide how many 'knowledge points' you would like to assign to each
        amount tile by clicking on each amount tile and allocating points by
        clicking on the slider and dragging the button or typing a number into
        the white-filled area.
      </p>
      <p className="mt-4">Note:</p>
      <ul className="pl-4">
        <li>You must assign 0 to 100 points to each tile</li>
        <li>
          You do not have to use all 300 points but cannot use more than 300
        </li>
        <li>
          The number of points in each amount tile equals the probability you
          will find out about the amount in it
        </li>
        <li>
          The right side of the table lets you know how many knowledge points
          you have left and whether you've exceeded the 300-point allocation
          limit
        </li>
      </ul>
    </div>
  );

  let InstructionText = null;
  if (!hideInstructions) {
    InstructionText = (
      <div className="mt-6 text-left">
        {confirmedAllocation ? (
          <div className="flex flex-col gap-y-4">
            <p>
              Notice the amount tiles have been updated with either amount
              information or "unknown".
            </p>
            <p>
              {canChooseGamble
                ? "Now you must choose which of the two gambles you'd like to play " +
                  "for real money by clicking on the 'Choose Gamble A' or 'Choose " +
                  "Gamble B' button."
                : "Now you must click on the 'Choose Random Gamble' button, which " +
                  "will lead to the computer choosing at random which gamble is " +
                  "played for real money."}
            </p>
            <p>
              After you click, notice that the CPU lets you know which was
              selected by flashing a green rectangle around the selected gamble.
            </p>
          </div>
        ) : isValidPointsAssignment ? (
          <div>
            <p>
              Now that you've allocated a valid number of knowledge points to
              each tile, the "Confirm Point Assignment" is green. This means the
              button is active, so you can now click on it or continue to modify
              your point allocations.
            </p>
            <p className="mt-4">
              When you click on the button, the computer determines
              probabilistically whether the amount in the tile is shown based on
              the number of 'knowledge points' assigned to it (e.g., 50
              knowledge points = 50% chance of the amount being shown; 0 = 0%;
              100 = 100%; etc.)
            </p>
          </div>
        ) : (
          <PreSelectionInstructionText />
        )}
      </div>
    );
  }

  let ButtonComponent;
  if (!confirmedAllocation) {
    ButtonComponent = (
      <div className="w-40 m-auto">
        <Button
          disabled={!isValidPointsAssignment}
          onClick={() => {
            const currTime = new Date();
            timer1End.current = currTime;
            timer2Start.current = currTime;
            setConfirmedAllocation(true);
          }}
        >
          Confirm Point Assignment
        </Button>
      </div>
    );
  } else {
    if (randomSelection) {
      ButtonComponent = (
        <div className="w-40 m-auto">
          <Button
            onClick={() => {
              timer2End.current = new Date();
              chooseRandomGamble();
            }}
            state={chooseButtonState}
            disabled={chooseButtonState !== ButtonState.DEFAULT}
          >
            Choose Random Gamble
          </Button>
        </div>
      );
    } else {
      ButtonComponent = (
        <div className="flex gap-x-8 justify-center">
          <div className="w-40">
            <Button
              onClick={() => {
                timer2End.current = new Date();
                chooseGambleA();
              }}
              state={chooseButtonAState}
              disabled={
                chooseButtonAState !== ButtonState.DEFAULT ||
                chooseButtonBState !== ButtonState.DEFAULT
              }
            >
              Choose Gamble A
            </Button>
          </div>
          <div className="w-40">
            <Button
              onClick={() => {
                timer2End.current = new Date();
                chooseGambleB();
              }}
              state={chooseButtonBState}
              disabled={
                chooseButtonBState !== ButtonState.DEFAULT ||
                chooseButtonAState !== ButtonState.DEFAULT
              }
            >
              Choose Gamble B
            </Button>
          </div>
        </div>
      );
    }
  }

  return (
    <div className="relative">
      <div
        className={`h-[144px] w-[690px] border-2 border-green-400 absolute top-[58px] 
        -left-[10px] animate-[blink_1s_infinite] ${gambleAVisibility}`}
      />
      <div
        className={`h-[144px] w-[690px] border-2 border-green-400 absolute top-[202px] 
        -left-[10px] animate-[blink_1s_infinite] ${gambleBVisibility}`}
      />
      <div
        className="grid grid-rows-[50px_1fr_1fr] grid-flow-col
      gap-4 justify-items-center items-center"
      >
        <div />
        <BoardHeading>Gamble A</BoardHeading>
        <BoardHeading>Gamble B</BoardHeading>

        <BoardHeading>{lossOnLeft ? lossProbText : winProbText}</BoardHeading>
        <div>
          <Card>{lossOnLeft ? lossProb : winProb}%</Card>
        </div>
        <div>
          <Card>{lossOnLeft ? lossProb : winProb}%</Card>
        </div>

        <BoardHeading>{lossOnLeft ? lossAmtText : winAmtText}</BoardHeading>
        <div>
          <DynamicCard
            isFake={false}
            isLossCard={lossOnLeft}
            onInitialClick={() => setClicked1(true)}
            confirmedAllocation={confirmedAllocation}
            onPointsChange={(points) => setPoints1(points)}
            onAmountReveal={(amountInfo) => (amount1Info.current = amountInfo)}
          />
        </div>
        <div>
          <DynamicCard
            isFake={false}
            isLossCard={lossOnLeft}
            onInitialClick={() => setClicked2(true)}
            confirmedAllocation={confirmedAllocation}
            onPointsChange={(points) => setPoints2(points)}
            onAmountReveal={(amountInfo) => (amount2Info.current = amountInfo)}
          />
        </div>

        <BoardHeading>{lossOnLeft ? winAmtText : lossAmtText}</BoardHeading>
        <div>
          <DynamicCard
            isFake={false}
            isLossCard={!lossOnLeft}
            onInitialClick={() => setClicked3(true)}
            confirmedAllocation={confirmedAllocation}
            onPointsChange={(points) => setPoints3(points)}
            onAmountReveal={(amountInfo) => (amount3Info.current = amountInfo)}
          />
        </div>
        <div>
          <DynamicCard
            isFake={false}
            isLossCard={!lossOnLeft}
            onInitialClick={() => setClicked4(true)}
            confirmedAllocation={confirmedAllocation}
            onPointsChange={(points) => setPoints4(points)}
            onAmountReveal={(amountInfo) => (amount4Info.current = amountInfo)}
          />
        </div>

        <BoardHeading>{lossOnLeft ? winProbText : lossProbText}</BoardHeading>
        <div>
          <Card>{lossOnLeft ? winProb : lossProb}%</Card>
        </div>
        <div>
          <Card>{lossOnLeft ? winProb : lossProb}%</Card>
        </div>

        <div></div>
        <div className="row-span-2 text-left pl-2">
          <strong>Knowledge Points Remaining:</strong>
          <p>{pointsLeft}</p>
          {pointsLeft < 0 && (
            <p className="text-rose-500 mt-3">
              Please remove {-pointsLeft} points!
            </p>
          )}
        </div>
      </div>
      <div className="mt-8 flex justify-center">
        <div className="flex flex-col gap-4 items-center">
          <div>{ButtonComponent}</div>
          {hideInstructions && (
            <Button
              state={ButtonState.SECONDARY}
              onClick={() => setModalOpen(true)}
            >
              Instructions
            </Button>
          )}
        </div>
        <div className="w-6"></div>
      </div>
      {InstructionText}
      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        <div className="flex flex-col gap-6 items-center">
          <PreSelectionInstructionText />
          <Button onClick={() => setModalOpen(false)}>Close</Button>
        </div>
      </Modal>
    </div>
  );
}
