export const ButtonState = {
  DEFAULT: "default",
  SECONDARY: "secondary",
  FAIL: "red",
  PASS: "green",
};

export default function Button({
  children,
  onClick,
  state = ButtonState.DEFAULT,
  disabled,
}) {
  let bg;
  let hoverBg;
  let disabledStyle;

  if (state === ButtonState.DEFAULT) {
    bg = "bg-teal-600";
    hoverBg = "hover:bg-teal-700";
    disabledStyle = "disabled:bg-gray-300 disabled:cursor-not-allowed";
  } else if (state === ButtonState.SECONDARY) {
    bg = "bg-gray-400";
    hoverBg = "hover:bg-gray-500";
    disabledStyle = "disabled:bg-gray-300 disabled:cursor-not-allowed";
  } else if (state === ButtonState.FAIL) {
    bg = "bg-rose-500";
  } else if (state === ButtonState.PASS) {
    bg = "bg-green-500";
  }

  return (
    <button
      className={`py-2 px-5 text-white rounded-md ${bg} ${hoverBg} ${disabledStyle}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
