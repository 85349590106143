import { useEffect, useState } from "react";
import Button from "../shared/Button";
import Input from "../shared/Input";
import UofTLogo from "../assets/uoft_logo.png";

export default function PersonalInfo({ workerId, onCancel, onSubmit }) {
  const [currentWorkerId, setCurrentWorkerId] = useState(workerId);
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");

  useEffect(() => {
    setCurrentWorkerId(workerId);
  }, [workerId]);

  return (
    <div className="flex justify-center mt-10">
      <div
        className="text-left flex flex-col gap-y-5 rounded-md border-[1px]
       border-gray-400 px-4 py-5 max-w-[500px] min-w-[350px] w-full"
      >
        <div className="m-auto my-6">
          <img src={UofTLogo} alt="U of T Logo" width={200} />
        </div>
        <div>
          <label htmlFor="workerId" className="mb-2 block">
            Worker ID (we need this to compensate you)
          </label>
          <Input
            id="workerId"
            value={currentWorkerId}
            disabled={workerId !== ""}
            onChange={(text) => setCurrentWorkerId(text)}
          />
        </div>
        <div>
          <label htmlFor="age" className="mb-2 block">
            Age
          </label>
          <Input id="age" onChange={(text) => setAge(text)} />
        </div>
        <div>
          <label htmlFor="gender" className="mb-2 block">
            Gender
          </label>
          <Input id="gender" onChange={(text) => setGender(text)} />
        </div>

        <div className="flex gap-x-3 justify-end mt-4">
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            onClick={() => {
              if (currentWorkerId && age && gender) {
                onSubmit({ workerId: currentWorkerId, age, gender });
              } else {
                alert("Please fill out all fields");
              }
            }}
          >
            Ok
          </Button>
        </div>
      </div>
    </div>
  );
}
