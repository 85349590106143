export default function Card({ children, onClick }) {
  return (
    <div
      role={onClick ? "button" : null}
      onClick={onClick}
      className="rounded-[4px] p-2 bg-gray-400 w-32 h-32 flex justify-center items-center 
    text-white text-2xl"
    >
      <div>{children}</div>
    </div>
  );
}
