import { useState, Fragment } from "react";
import Button from "../shared/Button";
import Select from "../shared/Select";

export default function Questionnaire3({ onSubmit }) {
  const questions = [
    [
      "stickToSpendingPlan",
      "It is hard to stick to my spending plan when unexpected expenses arise.",
    ],
    [
      "makeProgress",
      "It is challenging to make progress toward my financial goals.",
    ],
    [
      "unexpectedExpenses",
      "When unexpected expenses occur I usually have to use credit.",
    ],
    [
      "financialChallenge",
      "When faced with a financial challenge, I have a hard time figuring out a solution.",
    ],
    [
      "lackConfidence",
      "I lack confidence in my ability to manage my finances.",
    ],
    ["retirementMoney", "I worry about running out of money in retirement."],
  ];

  const [selected, setSelected] = useState(
    new Array(questions.length).fill(null)
  );

  const onSubmitResponse = () => {
    if (selected.includes(null)) {
      alert("Please select an option for each question");
      return;
    }

    const keyValuesArray = new Map(
      selected.map((value, index) => [questions[index][0], value])
    );

    const answerData = Object.fromEntries(keyValuesArray);
    onSubmit(answerData);
  };

  const Questions = questions.map((question, index) => (
    <Fragment key={index}>
      <strong>{question[1]}</strong>
      <div className="mt-5 mb-8">
        <Select
          options={[
            [1, "Not at all true"],
            [2, "Hardly true"],
            [3, "Moderately true"],
            [4, "Exactly true"],
          ]}
          onChange={(option) => {
            const newSelected = [...selected];
            newSelected[index] = option;
            setSelected(newSelected);
          }}
          selected={selected[index]}
        />
      </div>
    </Fragment>
  ));

  return (
    <div className="flex flex-col items-center">
      <p className="mb-3">Please rate these statements:</p>

      {Questions}

      <div className="w-40">
        <Button onClick={onSubmitResponse}>Submit</Button>
      </div>
    </div>
  );
}
