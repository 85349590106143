import ReactProgressBar from "@ramonak/react-progress-bar";

export default function ProgressBar({ completed }) {
  return (
    <ReactProgressBar
      height="8px"
      bgColor="#16a34a"
      isLabelVisible={false}
      completed={completed}
    />
  );
}
