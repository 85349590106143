import Banner from "../assets/banner.png";
import Button from "../shared/Button";
import Title from "../shared/Title";

export default function Consent({ onCancel, onAccept }) {
  return (
    <div className="w-[800px] m-auto">
      <div className="mb-6">
        <img src={Banner} alt="Rotman Logo" />
      </div>
      <Title>Online Consent Form</Title>
      <div className="flex flex-col gap-6 text-left">
        <p>
          You are invited to participate in research being conducted by Matthew
          Hilchey, a post-doctoral fellow at the University of Toronto's Rotman
          School of Management. This research is being supervised by Professor
          Dilip Soman. The research is funded by a partnership grant awarded by
          the Social Sciences and Humanities Research Council (SSHRC) of Canada.
          Your participation in this study is strictly voluntary, and you may
          refuse to participate or withdraw from the study, at any time, by
          closing your browser. If you decide to withdraw from the study, your
          data will be deleted immediately, not used in any analyses, and you
          will not be compensated.
        </p>
        <p>
          In this study, you will be shown 14 pairs of gambles ('Gamble A' and
          'Gamble B') on a computer screen, with each gamble offering some
          probability of losing or winning some amount of money. Your odds of
          winning/losing and the amounts you can win/lose on each gamble are
          displayed in a 2 x 4 tiled grid. Whereas all four probabilities are
          always readily available to you, the four amounts you can win/lose are
          not. To have a chance of finding out about amount information, you
          must click on each win/lose amount tile and then indicate to what
          extent you would like to learn about the outcome by assigning
          'knowledge points' to it. With each 'knowledge point' you add to a
          tile, you increase your probability of finding out about the amount
          underneath it by 1%. Critically, you only have 300 knowledge points to
          distribute, which means you cannot guarantee knowledge about all 4
          outcomes. After you've confirmed what amounts you do or do not want to
          know, none, some or all of the amounts will be shown to you. At this
          point, one of the two gambles must be selected. The gamble that is
          selected is played for real money and its outcome is determined by an
          algorithm that is faithful to the odds and amounts for the selected
          gamble. In the end, we'll ask you a handful of questions as part of an
          exit survey and then show you the outcomes for each selected gamble
          and the sum of all outcomes. Additional instructions and an
          opportunity to practice will be provided if you consent to
          participate. Note you will be awarded $1 US for completing this task.
          You will also start out with a $3 US endowment that is adjusted at the
          end, based on your outcomes. The adjusted endowment will be paid out
          to you as a bonus, if it exceeds $0 US upon completion. This study
          should take about 10-20 minutes to complete.
        </p>
        <p>
          Your participation in this study is confidential. Your data will be
          associated with a random number and your M-Turk ID # so that we can
          compensate you. The data that we collect from this website are
          encrypted and stored securely. The data that you provide will be
          accessible to Dr. Hilchey, Prof. Soman and researchers working under
          their supervision. There are no known significant risks associated
          with participating in this study and you are expected to benefit only
          indirectly by contributing to scientific research.
        </p>
        <p>
          If you would like further information regarding this study, you may
          contact: Matthew Hilchey at 416-864-8057 or by email:{" "}
          <a
            href="mailto:matthew.hilchey@rotman.utoronto.ca"
            className="underline text-blue-600"
          >
            matthew.hilchey@rotman.utoronto.ca
          </a>
          , or Dilip Soman at 416-946-0195 or by e-mail:{" "}
          <a
            href="mailto:dilip.soman@rotman.utoronto.ca"
            className="underline text-blue-600"
          >
            dilip.soman@rotman.utoronto.ca
          </a>
          . If you would like further information regarding your rights as a
          participant, you may contact the Ethics Review Office, 416-946-3273 or{" "}
          <a
            href="mailto:ethics.review@utoronto.ca"
            className="underline text-blue-600"
          >
            ethics.review@utoronto.ca
          </a>
          .
        </p>
        <p>
          If this information is not clear or if you do not consent to
          participate, then please click on the 'I do not consent' button below.
          If the preceding information is clear to you and you consent to
          participate, then please click on the 'I do consent' button below. By
          clicking on 'I do consent', you indicate that you understand your
          rights as a participant and give your consent to participate in this
          study. If you would like to retain a copy of this form for your
          records, please contact Matt Hilchey (
          <a
            href="mailto:matthew.hilchey@rotman.utoronto.ca"
            className="underline text-blue-600"
          >
            matthew.hilchey@rotman.utoronto.ca
          </a>
          ).
        </p>
      </div>

      <div className="mt-6 flex justify-center gap-8">
        <div className="w-40">
          <Button onClick={onCancel}>I do not consent to participate</Button>
        </div>
        <div className="w-40">
          <Button onClick={onAccept}>I consent to participate</Button>
        </div>
      </div>
    </div>
  );
}
