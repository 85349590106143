import Option from "./Option";

export default function Select({ options, onChange, selected }) {
  const Options = options.map((option) => (
    <Option
      key={option[0]}
      isSelected={option[0] === selected}
      onClick={() => onChange(option[0])}
    >
      {option[1]}
    </Option>
  ));

  return <div>{Options}</div>;
}
