import Button from "../shared/Button";
import TD from "../shared/TableCell";
import Title from "../shared/Title";

export default function DebriefForm({ onContinue }) {
  return (
    <div className="w-[800px] m-auto flex flex-col items-center">
      <Title>Debriefing</Title>

      <div className="flex flex-col gap-y-4 text-left">
        <p>
          The goal of this research is to assess how people's strategies for
          information gathering about potential wins or losses vary depending on
          whether they can or cannot do something to influence outcomes. The
          research is motivated by recent papers. Some show that people are
          reluctant to seek out information that likely signals a loss and are
          relatively eager to seek out information that likely signals a gain
          (Charpentier et al., 2018; Lieshout et al., 2021), whereas others show
          the opposite result (Lejarraga, Schulte-Mecklenbeck, Pachur & Hertwig,
          2019; Yechiam, Zahavi & Arditi, 2015). One of the goals of today's
          study is to better characterize and understand the nature of these
          phenomena. Another goal of the research is to identify interventions
          for helping people overcome avoidance behavior to potentially bad news
          (e.g., deciding not to look at a bill, medical test, investment
          return, tax filing, etc., because it may signal an aversive outcome).
        </p>
        <p>
          You were randomly assigned to one of two versions of this task. In one
          version, the participant got to choose each time which gamble was
          played and in the other this choice was always made randomly by the
          computer. We are interested specifically in whether demand for
          information about potential wins and losses changes depending on
          whether people have choices that can influence outcomes. We will
          determine demand for knowledge about wins and losses across different
          win and loss probabilities in both versions by evaluating whether and
          how many knowledge points were assigned to win and loss amount tiles.
          We will also evaluate the extent to which these strategies are
          impacted by people's sense of control over financial outcomes (Lowe,
          2011)
        </p>
        <p>
          We vary whether people can choose to play lotteries because the
          broader scientific literature suggests this may matter. More
          specifically, people may pay more attention to financial losses when
          they believe they can take action to avoid them whereas they may pay
          more attention to financial gains when they believe they cannot take
          action to avoid them. If this hypothesis is correct, an implication is
          that people are least likely to seek out a potentially bad outcome
          when they either do not or believe they do not have opportunities to
          do anything about prospectively negative outcomes.
        </p>
        <p>
          By contributing to this research project today, you have helped us
          better understand what leads to information avoidance and seeking in
          the financial domain. We will use this knowledge to develop theories
          and, ideally, to help people overcome aversion to negative outcome
          information when knowledge of it matters most.
        </p>
        <p>
          Should you have any further inquiries regarding the study you
          participated, please feel free to contact the research team with the
          following contact information:
        </p>
        <table className="border border-black w-3/4 m-auto my-4 pt-7">
          <tbody>
            <tr>
              <TD>Researcher Name:</TD>
              <TD>Matthew Hilchey</TD>
            </tr>
            <tr>
              <TD>Researcher Email:</TD>
              <TD>
                <a
                  className="underline text-blue-600"
                  href="mailto:matthew.hilchey@rotman.utoronto.ca"
                >
                  matthew.hilchey@rotman.utoronto.ca
                </a>
              </TD>
            </tr>
            <tr>
              <TD>Researcher Phone Number:</TD>
              <TD>(416) 864-8057</TD>
            </tr>
            <tr>
              <TD>Supervisor Name:</TD>
              <TD>Dilip Soman</TD>
            </tr>
            <tr>
              <TD>Supervisor Email:</TD>
              <TD>
                <a
                  className="underline text-blue-600"
                  href="mailto:dilip.soman@rotman.utoronto.ca"
                >
                  dilip.soman@rotman.utoronto.ca
                </a>
              </TD>
            </tr>
            <tr>
              <TD>Supervisor Phone Number:</TD>
              <TD>(416) 946-0195</TD>
            </tr>
          </tbody>
        </table>
        <p>
          If you would like to receive a project report when the project is
          completed, you may contact Matt Hilchey (above). If you would like
          further information regarding your rights as a participant, you may
          contact the Ethics Review Office, 416-946-3273 or{" "}
          <a
            href="mailto:ethics.review@utoronto.ca"
            className="underline text-blue-600"
          >
            ethics.review@utoronto.ca
          </a>
          .
        </p>
        <p className="-mb-2">
          <strong>References:</strong>
        </p>
        <p>
          Golman, R., & Loewenstein, G. (2018). Information gaps: A theory of
          preferences regarding the presence and absence of information.{" "}
          <span className="italic">Decision, 5</span>(3), 143-164.
        </p>
        <p>
          Johnson, E. J., Schulte-Mecklenbeck, M., & Willemsen, M. C. (2008).
          Process models deserve process data: Comment on Brandstätter,
          Gigerenzer, and Hertwig (2006).{" "}
          <span className="italic">Psychological Review, 115</span>(1), 263-272
        </p>
        <p>
          Karlsson, N., Loewenstein, G., & Seppi, D. (2009). The ostrich effect:
          Selective attention to information.{" "}
          <span className="italic">Journal of Risk and uncertainty, 38</span>
          (2), 95-115.
        </p>
        <p>
          Lejarraga, T., Schulte-Mecklenbeck, M., Pachur, T., & Hertwig, R.
          (2019). The attention-aversion gap: how allocation of attention
          relates to loss aversion.{" "}
          <span className="italic">Evolution and human behavior, 40</span>(5),
          457-469.
        </p>
        <p>
          Yechiam, E., Zahavi, G., & Arditi, E. (2015). Loss restlessness and
          gain calmness: Durable effects of losses and gains on choice
          switching.{" "}
          <span className="italic">Psychonomic bulletin & review, 22</span>(4),
          1096-1103.
        </p>
      </div>

      <div className="w-40 mt-8">
        <Button onClick={onContinue}>Continue</Button>
      </div>
    </div>
  );
}
