import useLocalStorage from "../hooks/useLocalStorage";
import Button from "../shared/Button";

export default function Instructions({ onAccept }) {
  const [canChooseGamble] = useLocalStorage("canChooseGamble", null);

  let chooseGambleText = null;
  if (canChooseGamble) {
    chooseGambleText = (
      <li>
        You then choose which of the two gambles you'd like to play by clicking
        on either the '<strong>Choose Gamble A</strong>' or '
        <strong>Choose Gamble B</strong>' button
      </li>
    );
  } else {
    chooseGambleText = (
      <li>
        You must then click on the '<strong>Choose Random Gamble</strong>'
        button, which will lead to the computer selecting at random which gamble
        is played
      </li>
    );
  }

  return (
    <div className="w-[800px] m-auto">
      <h1 className="text-2xl text-red-500 font-bold mb-6">
        INSTRUCTIONS: Read Carefully
      </h1>
      <div className="text-left flex flex-col gap-y-6">
        <div>
          <p className="mb-4">
            <strong>
              You will be shown 14 pairs of win/lose gambles (Gamble A vs Gamble
              B), one pair at a time
            </strong>
          </p>
          <ul className="pl-8">
            <li>
              Each gamble offers some probability of winning and losing some
              amount of <strong>real money</strong>
            </li>
            <li>
              Win/loss amounts and probabilities for each gamble are arranged in
              a <strong>2 x 4 tiled grid</strong>, with row names '
              <strong>Gamble A</strong>' and '<strong>Gamble B</strong>' and
              column names '<strong>Win Probability</strong>', '
              <strong>Win Amount</strong>', '<strong>Loss Probability</strong>',
              and '<strong>Loss Amount</strong>' (or{" "}
              <span className="italic">vice versa</span>)
            </li>
            <li>
              Each win amount is a randomly-generated value between{" "}
              <strong>+10 and +99 cents</strong> and each loss amount is a
              randomly-generated value between
              <strong>-10 and -99 cents</strong>
            </li>
            <li>
              The probability of winning on any given gamble varies between{" "}
              <strong>5% and 95%</strong>, and the probability of losing is{" "}
              <strong>100% minus the probability of winning</strong>
              <ul className="pl-8">
                <li>
                  On each pair of gambles, both gambles offer the same
                  probability of winning but the amounts at stake differ by
                  random chance
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div>
          <p className="mb-4">
            <strong>Rules</strong>
          </p>
          <ul className="pl-8">
            <li>
              Each gamble's win/lose probably is always made readily available
              to you in the '<strong>Win Probability</strong>' and '
              <strong>Lose Probability</strong>' columns of the 2 x 4 tiled grid
            </li>
            <li>
              Win/lose amount is <strong>not made readily visible</strong> to
              you in the '<strong>Win Amount</strong>' and '
              <strong>Lose Amount</strong>' columns and you must therefore
              decide what you do or do not want to know
            </li>
            <li>
              Decide which amounts you do and do not want to know by clicking on
              amount tiles and distributing '<strong>knowledge points</strong>'
              to them:
              <ul className="pl-8">
                <li>
                  On each pair of gambles, you have '
                  <strong>300 knowledge points</strong>' to distribute across
                  the 4 amount tiles, as you please
                </li>
                <li>
                  You get to decide whether you use all, none or a fraction of
                  these points each time but{" "}
                  <strong>can never use more than 300</strong>
                </li>
                <li>
                  Each '<strong>knowledge point</strong>' that you assign to an
                  amount tile increases the probability you will find out about
                  that amount by <strong>1%</strong>
                </li>
                <li>
                  After you've decided on your knowledge point allocation, an
                  algorithm determines probabilistically whether the amount in
                  the tile is shown based on the number of '
                  <strong>knowledge points</strong>' assigned to it (e.g., 50
                  knowledge points = 50% chance of the amount being shown; 0 =
                  0% and 100 = 100%)
                </li>
              </ul>
            </li>
            {chooseGambleText}
            <li>
              The gamble that was played will be shown to you, but the outcome
              from it will not be. All outcomes are recorded automatically and
              these, and the sum of them, will be shown to you at the end
            </li>
          </ul>
        </div>
        <div>
          <p className="mb-4">
            <strong>Payouts and Eligibility</strong>
          </p>
          <ul className="pl-8">
            <li>
              You are eligible for compensation if:
              <ul className="pl-8">
                <li>
                  You have <strong>NOT</strong> already completed this task
                </li>
                <li>You complete the task</li>
                <li>
                  You enter the survey code shown at the end into M-Turk when
                  submitting the HIT
                </li>
              </ul>
            </li>
            <li>
              All eligible participants receive a minimum of{" "}
              <strong>$1 US</strong> for completing this task
              <ul className="pl-8">
                <li>
                  Your winnings and losses will be added and subtracted,
                  respectively, from the initial endowment of $3 US. The
                  adjusted amount will be paid out to you as a bonus if it
                  exceeds $0 US upon completion of the task.
                </li>
              </ul>
            </li>
          </ul>
          <p className="mt-4">
            Before we play for real money, we'll let you practice so that you
            can see how this works. We'll let you know when practice is over and
            therefore playing for real money.
          </p>
        </div>
      </div>
      <div className="mt-6">
        <Button onClick={onAccept}>Continue</Button>
      </div>
    </div>
  );
}
