import { useState } from "react";
import Button from "../shared/Button";
import Select from "../shared/Select";

export default function Questionnaire2({ onSubmit }) {
  const [selected1, setSelected1] = useState(null);
  const [selected2, setSelected2] = useState(null);

  const onSubmitResponse = () => {
    if (selected1 === null || selected2 === null) {
      alert("Please select an option for each question");
      return;
    }

    onSubmit({ minimizedLoss: selected1, maximizedWin: selected2 });
  };

  return (
    <div className="flex flex-col items-center">
      <p className="mb-3">Please rate these statements:</p>
      <strong>
        I believe that I was able to make choices that minimized the amount of
        money I would lose.
      </strong>
      <div className="my-5">
        <Select
          options={[
            [1, "Strongly Disagree(1)"],
            [2, "2"],
            [3, "3"],
            [4, "Neither Agree nor Disagree(4)"],
            [5, "5"],
            [6, "6"],
            [7, "Strongly Agree(7)"],
          ]}
          onChange={(option) => setSelected1(option)}
          selected={selected1}
        />
      </div>

      <strong className="mt-5">
        I believe I was able to make choices that would maximize the amount of
        money I would win.
      </strong>
      <div className="my-5">
        <Select
          options={[
            [1, "Strongly Disagree(1)"],
            [2, "2"],
            [3, "3"],
            [4, "Neither Agree nor Disagree(4)"],
            [5, "5"],
            [6, "6"],
            [7, "Strongly Agree(7)"],
          ]}
          onChange={(option) => setSelected2(option)}
          selected={selected2}
        />
      </div>

      <div className="w-40">
        <Button onClick={onSubmitResponse}>Submit</Button>
      </div>
    </div>
  );
}
