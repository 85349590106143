export default function Slider({ value, min, max, step, onChange }) {
  return (
    <div className="flex gap-x-1 items-center">
      <div className="w-full flex justify-between">
        <label>{min}</label>
        <label>{max}</label>
      </div>

      {value === "" && (
        <div className="absolute w-16 ml-4 h-1.5 rounded-md cursor-pointer bg-gray-600"></div>
      )}

      <input
        type="range"
        value={`${value}`}
        min={`${min}`}
        max={`${max}`}
        step={`${step}`}
        className={
          "absolute w-16 ml-4 h-1.5 rounded-md appearance-none cursor-pointer " +
          "bg-gray-600 range-lg " +
          (value === "" ? "opacity-0" : "")
        }
        onClick={value === "" ? () => onChange(0) : null}
        onChange={(e) => onChange(parseInt(e.target.value))}
      />
    </div>
  );
}
