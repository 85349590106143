import { useEffect } from "react";

export default function Reset() {
  useEffect(() => {
    window.location.href = "/";

    localStorage.removeItem("roundProbabilities");
    localStorage.removeItem("step");
    localStorage.removeItem("workerId");
    localStorage.removeItem("canChooseGamble");
    localStorage.removeItem("lossOnLeftInitially");
    localStorage.removeItem("surveyCode");
    localStorage.removeItem("gambleResults");
    localStorage.removeItem("round");
    localStorage.removeItem("token");
  }, []);

  return (
    <div className="p-5">
      <h1 className="text-lg text-center">Website is now reset.</h1>
    </div>
  );
}
