import { useEffect, useRef, useState } from "react";
import { randInt } from "../utils/utilFunctions";
import Card from "./Card";
import Slider from "./Slider";
import SmallInput from "./SmallInput";

export default function DynamicCard({
  isFake,
  isLossCard,
  onPointsChange,
  confirmedAllocation,
  onInitialClick,
  onAmountReveal,
}) {
  const [clicked, setClicked] = useState(false);
  const [clickedSlider, setClickedSlider] = useState(false);
  const [points, setPoints] = useState("");
  const amount = useRef(null);
  const shouldShowAmount = useRef(false);

  useEffect(() => {
    if (!clickedSlider && points !== "") {
      setClickedSlider(true);
      onInitialClick();
    }

    if (typeof points === "number") {
      onPointsChange(points);
    }
  }, [points, onPointsChange, clickedSlider, onInitialClick]);

  let Component;
  if (!clicked) {
    Component = <p className="text-sm">Click to Assign Knowledge Points</p>;
  } else if (!confirmedAllocation) {
    Component = (
      <div className="flex flex-col text-sm gap-y-2">
        <Slider
          value={points}
          min={0}
          max={100}
          step={5}
          onChange={(val) => setPoints(val)}
        />
        <div className="text-gray-700">
          <SmallInput
            value={points !== undefined ? `${points}` : ""}
            onChange={(text) => {
              const val = parseInt(text) || 0;
              setPoints(Math.max(0, Math.min(val, 100)));
            }}
          />
          <span className="text-white ml-1.5">%</span>
        </div>
        <p className="text-xs">Chance of seeing outcome</p>
      </div>
    );
  } else {
    if (amount.current === null) {
      if (isFake) {
        const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const letter1 = randInt(0, 25);
        const letter2 = randInt(0, 25);
        amount.current = `${alphabet[letter1]}${alphabet[letter2]}`;
      } else {
        amount.current = randInt(10, 99);
      }

      if (isLossCard) {
        amount.current = `-${amount.current}`;
      } else {
        amount.current = `+${amount.current}`;
      }

      shouldShowAmount.current = Math.random() < points / 100;
      onAmountReveal({
        amountShowing: shouldShowAmount.current,
        amount: amount.current,
      });
    }

    if (shouldShowAmount.current) {
      Component = <p>{amount.current}&#162;</p>;
    } else {
      Component = <p className="text-base">Unknown</p>;
    }
  }

  return (
    <Card onClick={!clicked ? () => setClicked(true) : null}>{Component}</Card>
  );
}
