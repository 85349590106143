export default function SmallInput({ id, value, onChange }) {
  return (
    <input
      id={id || ""}
      className="px-1 rounded-md w-10 text-center"
      value={value || ""}
      onChange={(e) => onChange(e.target.value)}
    />
  );
}
