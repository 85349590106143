import Button from "../shared/Button";

export default function HalfwayPage({ roundsLeft, onContinue }) {
  return (
    <div className="h-[calc(100vh_-_120px)] flex flex-col justify-center">
      <p>You're halfway done ({roundsLeft} lotteries left)!</p>
      <div className="mt-6">
        <p>
          Pay attention to the column names on the 4 x 2 grid because these are
          now different.
        </p>
        <p>
          Win Amounts are where Loss Amounts used to be and Loss Amounts are
          where Win Amounts used to be!
        </p>
      </div>
      <div className="mt-10">
        <Button onClick={onContinue}>Continue</Button>
      </div>
    </div>
  );
}
