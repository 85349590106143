import axios from "axios";
import { useEffect, useState } from "react";
import Button from "../shared/Button";
import Input from "../shared/Input";
import myWorker from "../workers/jsonParser.worker";

const downloadFile = (filename, url) => {
  // Automatically download file after it is processed
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  link.click();
  window.URL.revokeObjectURL(url);
};

const processAndDownloadData = (allData) => {
  const jsonToCSVWorker = new myWorker();
  jsonToCSVWorker.postMessage({ type: "jsonToCSV", allData });
  jsonToCSVWorker.onerror = (e) => {
    throw e;
  };

  jsonToCSVWorker.onmessage = (e) => {
    switch (e.data.type) {
      case "csvUrls":
        downloadFile("user_data.csv", e.data.userDataUrl);
        downloadFile("round_data.csv", e.data.roundDataUrl);
        break;
      default:
        break;
    }
  };
};

export default function Download() {
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [downloadFinished, setDownloadFinished] = useState(false);
  const [downloadSuccess, setDownloadSuccess] = useState(false);

  useEffect(() => {
    if (!token) return;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`/api/users/`, config)
      .then((response) => {
        processAndDownloadData(response.data);
        setDownloadFinished(true);
        setDownloadSuccess(true);
      })
      .catch((err) => {
        console.log(err);
        setDownloadFinished(true);
        setDownloadSuccess(false);
      });
  }, [token]);

  const LoginComponent = (
    <div className="flex justify-center mt-10">
      <div
        className="text-left flex flex-col gap-y-5 rounded-md border-[1px]
   border-gray-400 px-4 py-5 max-w-[500px] min-w-[350px] w-full"
      >
        <div>
          <label htmlFor="userId" className="mb-2 block">
            User ID
          </label>
          <Input id="userId" onChange={(text) => setUserId(text)} />
        </div>
        <div>
          <label htmlFor="password" className="mb-2 block">
            Password
          </label>
          <Input
            id="password"
            type="password"
            onChange={(text) => setPassword(text)}
          />
        </div>

        <div className="m-auto mt-4">
          <Button
            onClick={() => {
              if (userId && password) {
                const config = {
                  headers: {
                    "Content-Type": "application/json",
                  },
                };

                const body = {
                  userId,
                  password,
                };

                axios
                  .post(`/api/users/login`, body, config)
                  .then((response) => {
                    setToken(response.data.user.token);
                    setLoggedIn(true);
                  })
                  .catch((err) => {
                    console.log(err);
                    alert(err.response.data.message);
                  });
              } else {
                alert("Please fill out all fields");
              }
            }}
          >
            Login
          </Button>
        </div>
      </div>
    </div>
  );

  const Downloading = (
    <h1 className="text-lg text-center">Downloading all data...</h1>
  );

  const Failure = (
    <h1 className="text-lg text-center">
      There was an error downloading the data. Please try again later.
    </h1>
  );

  const Finished = (
    <h1 className="text-lg text-center">Successfully downloaded all data.</h1>
  );

  return (
    <div className="p-5">
      {!loggedIn
        ? LoginComponent
        : !downloadFinished
        ? Downloading
        : !downloadSuccess
        ? Failure
        : Finished}
    </div>
  );
}
