import axios from "axios";
import { useEffect, useState } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import Board from "../shared/Board";
import ProgressBar from "../shared/ProgressBar";
import HalfwayPage from "./HalfwayPage";

const PROBABILITIES = [5, 20, 35, 50, 65, 80, 95];
export const ROUNDS = PROBABILITIES.length * 2;

// From https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
const shuffle = (options) => {
  let currentIndex = options.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [options[currentIndex], options[randomIndex]] = [
      options[randomIndex],
      options[currentIndex],
    ];
  }

  return options;
};

export default function Game({
  canChooseGamble,
  lossOnLeftInitially,
  onGameFinish,
}) {
  const [results, setResults] = useLocalStorage(
    "gambleResults",
    new Array(ROUNDS).fill(0)
  );
  const [round, setRound] = useLocalStorage("round", -1);
  const [token] = useLocalStorage("token", "");
  const [workerId] = useLocalStorage("workerId", "");
  const [showHalfwayPage, setShowHalfwayPage] = useState(false);
  const [probabilityOptions, setProbabilityOptions] = useLocalStorage(
    "roundProbabilities",
    []
  );

  useEffect(() => {
    if (probabilityOptions.length === 0) {
      setProbabilityOptions([
        ...shuffle([...PROBABILITIES]),
        ...shuffle([...PROBABILITIES]),
      ]);
    }
  }, [probabilityOptions, setProbabilityOptions]);

  useEffect(() => {
    if (round === ROUNDS / 2) {
      setShowHalfwayPage(true);
    }

    if (round === ROUNDS) {
      onGameFinish();
    }
  }, [round, onGameFinish]);

  const shouldShowLossOnLeft = (round) =>
    round < ROUNDS / 2 ? lossOnLeftInitially : !lossOnLeftInitially;

  const handleRoundFinish = (data) => {
    // Real rounds
    let totalValue = "+0";

    if (round >= 0) {
      const newResults = [...results];
      newResults[round] = data.rewardAmount;
      setResults(newResults);

      // The above call to setResults will not be reflected here yet
      // so separately add the reward for the current round
      totalValue =
        results
          .slice(0, round)
          .reduce((acc, currNumber) => acc + parseInt(currNumber, 10), 0) +
        parseInt(data.rewardAmount, 10);

      if (totalValue < 0) {
        totalValue = `${totalValue}`;
      } else {
        totalValue = `+${totalValue}`;
      }
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const body = {
      roundData: {
        ...data,
        totalValue,
        canChooseGamble,
        lossOnLeft: shouldShowLossOnLeft(round),
      },
    };

    axios
      .post(`/api/users/${workerId}/rounds/${round}`, body, config)
      .then(() => {
        setRound(round + 1);
      })
      .catch((err) => {
        console.log(err);
        alert(
          "An error occured trying to save information about this round. Please try again later."
        );
      });
  };

  const exampleBoard = (
    <Board
      key={-1}
      lossOnLeft={lossOnLeftInitially}
      randomSelection={!canChooseGamble}
      winProb={50}
      onRoundFinish={(obj) => handleRoundFinish(obj)}
    />
  );

  let realBoard = (
    <Board
      key={round}
      hideInstructions
      lossOnLeft={shouldShowLossOnLeft(round)}
      randomSelection={!canChooseGamble}
      winProb={probabilityOptions[round]}
      onRoundFinish={(obj) => handleRoundFinish(obj)}
    />
  );

  const realBoardWithProgress = (
    <>
      <div className="w-[600px] m-auto mb-6">
        <ProgressBar completed={((round + 1) / ROUNDS) * 100} />
        <p className="mt-3">
          Round {round + 1} of {ROUNDS}
        </p>
      </div>
      {realBoard}
    </>
  );

  let BoardComponent;
  if (round === -1) {
    BoardComponent = exampleBoard;
  } else {
    BoardComponent = realBoardWithProgress;
  }

  const halfwayPage = (
    <HalfwayPage
      roundsLeft={ROUNDS - round}
      onContinue={() => setShowHalfwayPage(false)}
    />
  );

  return (
    <div className="w-[820px] m-auto">
      {showHalfwayPage ? halfwayPage : BoardComponent}
    </div>
  );
}
