import useLocalStorage from "../hooks/useLocalStorage";
import Button from "../shared/Button";
import { ROUNDS } from "./Game";

export default function GambleResults({ onContinue }) {
  const [results] = useLocalStorage("gambleResults", []);
  const totalWon = results.reduce((acc, result) => acc + parseInt(result), 0);

  const Labels = [...Array(ROUNDS).keys()].map((round) => {
    return (
      <p className="" key={round}>
        Gamble Outcome {round + 1}: {results[round] ? results[round] + "¢" : ""}
      </p>
    );
  });

  return (
    <div className="flex flex-col items-center">
      <p className="mb-3 text-2xl">Gamble Results</p>
      <div className="mt-4 text-left">{Labels}</div>
      <p className="mt-4">
        Total: <strong>{totalWon}¢</strong>
      </p>
      <div className="w-40 mt-8">
        <Button onClick={onContinue}>Continue</Button>
      </div>
    </div>
  );
}
