export default function Option({ children, isSelected, onClick }) {
  const bgColor = isSelected ? "bg-gray-500" : "bg-gray-300 hover:bg-gray-400";

  return (
    <div
      role="button"
      className={`w-[26rem] py-2 my-3 m-auto ${bgColor}`}
      onClick={isSelected ? undefined : onClick}
    >
      {children}
    </div>
  );
}
