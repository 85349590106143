import RNModal from "react-modal";

const modalStyle = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 700,
    borderRadius: 12,
  },
};

export default function Modal({ isOpen, onClose, children }) {
  return (
    <RNModal isOpen={isOpen} onRequestClose={onClose} style={modalStyle}>
      {children}
    </RNModal>
  );
}
