import { useState } from "react";
import Button from "../shared/Button";
import Select from "../shared/Select";

export default function Questionnaire({ onSubmit }) {
  const [selected, setSelected] = useState(null);

  const onSubmitResponse = () => {
    if (selected === null) {
      alert("Please select an option");
      return;
    }

    onSubmit({ actionsAffectMoney: selected });
  };

  return (
    <div className="flex flex-col items-center">
      <p className="mb-3">Please rate this statement:</p>
      <strong>
        I believe my actions affected the amount of money I won or lost.
      </strong>
      <div className="my-5">
        <Select
          options={[
            [1, "Strongly Disagree(1)"],
            [2, "2"],
            [3, "3"],
            [4, "Neither Agree nor Disagree(4)"],
            [5, "5"],
            [6, "6"],
            [7, "Strongly Agree(7)"],
          ]}
          onChange={(option) => setSelected(option)}
          selected={selected}
        />
      </div>

      <div className="w-40">
        <Button onClick={onSubmitResponse}>Submit</Button>
      </div>
    </div>
  );
}
