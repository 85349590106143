import axios from "axios";
import { useEffect } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import Button from "../shared/Button";

export default function SurveyCode({ onContinue }) {
  const [surveyCode, setSurveyCode] = useLocalStorage("surveyCode", "");
  const [token] = useLocalStorage("token", "");
  const [workerId] = useLocalStorage("workerId", "");

  useEffect(() => {
    if (!surveyCode) {
      const surveyCode = Math.floor(Math.random() * 90000) + 10000;

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const body = {
        surveyCode,
      };

      axios
        .post(`/api/users/${workerId}/survey-code`, body, config)
        .then(() => {
          setSurveyCode(surveyCode);
        })
        .catch((err) => {
          console.log(err);
          alert(
            "An error occured trying to generate the survey code. Please try again later."
          );
        });
    }
  }, [surveyCode, setSurveyCode, token, workerId]);

  return (
    <div className="flex flex-col items-center h-[calc(100vh-100px)] justify-center">
      <p className="text-2xl">Survey Code: {surveyCode}</p>
      <p className="mt-2">Please record this code before clicking Continue.</p>

      <div className="w-40 mt-8">
        <Button onClick={onContinue}>Continue</Button>
      </div>
    </div>
  );
}
