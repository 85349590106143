export default function Input({
  id,
  onChange,
  value,
  disabled,
  type = "text",
}) {
  return (
    <input
      id={id}
      value={value}
      type={type}
      disabled={disabled}
      className={
        `p-2 border-[2px] border-gray-300 rounded-md w-full ` +
        (disabled ? "bg-gray-200 text-gray-500 cursor-not-allowed" : "bg-white")
      }
      onChange={(e) => onChange(e.target.value)}
    />
  );
}
